/* https://gist.githubusercontent.com/DavidWells/18e73022e723037a50d6/raw/ead7e72d11c847ad9b81f094686543522101c278/reset.css */

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 800ms ease-in;
}


/**--- example styles ---**/
.container {
  padding: 20px;
  border: 1px solid #ccc;
  width: 200px;
  margin: auto;
  border-radius: 4px;
}

.todo-list > * {
  margin: 5px 0;
  padding: 5px 0;
  border-bottom:  1px solid #ccc;
}

.container > button {
  margin-top: 20px;
}

html, body {
font-size: 12px;
font-family: 'Toolabs Font', sans-serif;
font-weight: 300;
letter-spacing: 0.07em;
margin: 0;
padding: 0;
overflow : hidden;

-webkit-user-select: none;  /* Chrome all / Safari all */
-moz-user-select: none;     /* Firefox all */
-ms-user-select: none;      /* IE 10+ */
user-select: none;
}

a {
  color : inherit;
  text-decoration: none;
}
a:link {
}
a:visited {
}

a:hover {
  color : #cfcfcf;
}

a:active {
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
}

textarea:focus, input:focus, button:focus, div:focus{
  outline: none;
}

.cssgrid {
  display: grid;
  grid-auto-rows: 1fr;
}
.cssgrid::before {
  content: '';
  width: 0;
  padding-bottom: 100%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.cssgrid > *:first-child {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

/************** SORTABLE ****/
.sortitem-filtered {

}
.z-2000 {
  z-index: 2000;
}

ul {
  padding-left : 24px;
}


/*************** SCROLLBAR BASE CSS ***************/
/*
.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative;
}

.scroll-wrapper > .scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  padding: 0;
  position: relative !important;
  top: 0;
  width: auto !important;
}

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.scroll-element {
  display: none;
}
.scroll-element, .scroll-element div {
  box-sizing: content-box;
}

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
  display: block;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
  cursor: default;
}

.scroll-textarea {
  border: 1px solid #cccccc;
  border-top-color: #999999;
}
.scroll-textarea > .scroll-content {
  overflow: hidden !important;
}
.scroll-textarea > .scroll-content > textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  outline: none;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important;
}
.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
  height: 0;
  width: 0;
}


*/

/*************** SCROLLBAR MAC OS X ***************/
/*
.scrollbar-macosx > .scroll-element,
.scrollbar-macosx > .scroll-element div
{
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}

.scrollbar-macosx > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.scrollbar-macosx > .scroll-element .scroll-element_track { display: none; }
.scrollbar-macosx > .scroll-element .scroll-bar {
  background-color: #6C6E71;
  display: block;

  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;

  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;

  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.scrollbar-macosx:hover > .scroll-element .scroll-bar,
.scrollbar-macosx > .scroll-element.scroll-draggable .scroll-bar {
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  opacity: 0.7;
}


.scrollbar-macosx > .scroll-element.scroll-x {
  bottom: 0px;
  height: 0px;
  left: 0;
  min-width: 100%;
  overflow: visible;
  width: 100%;
}

.scrollbar-macosx > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 0px;
  top: 0;
  width: 0px;
}

/* scrollbar height/width & offset from container borders */
/*
.scrollbar-macosx > .scroll-element.scroll-x .scroll-bar { height: 7px; min-width: 10px; top: -9px; }
.scrollbar-macosx > .scroll-element.scroll-y .scroll-bar { left: -9px; min-height: 10px; width: 7px; }

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_outer { left: 2px; }
.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_size { left: -4px; }

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_outer { top: 2px; }
.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_size { top: -4px; }

/* update scrollbar offset if both scrolls are visible */
/*
.scrollbar-macosx > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size { left: -11px; }
.scrollbar-macosx > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size { top: -11px; }
*/


.styleboard_card {
  opacity: 0;
}
.styleboard_card_mounted {
  opacity: initial;
  /* transition: opacity 400ms ease; */
}
.styleboard_card_willunmount {
  opacity: 0;
  transition: opacity 300ms ease;
}
[contenteditable="true"]:focus{
  border:none;
  outline:none;
}

/******************** Component Designer ********************/

.designer_item_temp {
  opacity : 0;
}
.designer_item_temp_mounted {
  opacity: initial;
  transition: opacity 200ms ease;
}

.designer_item_temp_willunmount {
  opacity: 0;
  transition: opacity 200ms ease;
}

.designer_container_dropzone {
  transition: all 500ms ease;
}
.designer_container_dropzone_child {
  transition: all 500ms ease;
}

.designer_item_dragging_source {
  opacity: 0.7;
  transition: 200ms ease;
  transition-property: opacity;
  outline: 1px dashed #5C91FF;
}

.designer_item {
  outline: none;
}

.designer_item:hover {
  outline : 1px solid #1592E6;
}

.designboard_sizehandle_vert {
  transition: all 100ms ease;
}
.designboard_sizehandle_vert:hover {
  background-color: rgba(77, 167, 228, 0.37);
}

.designboard_sizehandle_vert::after {
  content: '';
  position: absolute;
  width : 8px;
  top : 0;
  bottom : 0;
  right : 4px;
  border-right: 1px dashed #ff4aff;
}
.designboard_sizehandle_hor {
  transition: all 100ms ease;
}
.designboard_sizehandle_hor:hover {
  background-color: rgba(77, 167, 228, 0.37);
}
.designboard_sizehandle_hor::after {
  content: '';
  position: absolute;
  height : 8px;
  top : 4px;
  left : 0;
  right : 0;
  border-top: 1px dashed #ff4aff;
}

.highlight-dark {
  color: #9feaf9;
  background-color: #2b2e3b;
  border-color: #111218; }
.highlight-dark code {
    color: inherit; }
.highlight-dark .c1.c1 {
    color: #719fad;
}



input, button, textarea {
  font-family : 'Toolabs Font', sans-serif;
  color : #AFAFAF;
}
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
border: unset;
-webkit-text-fill-color: unset;
-webkit-box-shadow: unset;
transition: unset;
background-color : unset;
}

.leader-line {
  z-index : 999999999999;
}

/*!
* Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
* Copyright 2015 Daniel Cardoso <@DanielCardoso>
* Licensed under MIT
*/
.la-ball-scale-multiple,
.la-ball-scale-multiple > div {
   position: relative;
   -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
           box-sizing: border-box;
}
.la-ball-scale-multiple {
   display: block;
   font-size: 0;
   color: #fff;
}
.la-ball-scale-multiple.la-dark {
   color: #333;
}
.la-ball-scale-multiple > div {
   display: inline-block;
   float: none;
   background-color: currentColor;
   border: 0 solid currentColor;
}
.la-ball-scale-multiple {
   width: 32px;
   height: 32px;
}
.la-ball-scale-multiple > div {
   position: absolute;
   top: 0;
   left: 0;
   width: 32px;
   height: 32px;
   border-radius: 100%;
   opacity: 0;
   -webkit-animation: ball-scale-multiple 1s 0s linear infinite;
      -moz-animation: ball-scale-multiple 1s 0s linear infinite;
        -o-animation: ball-scale-multiple 1s 0s linear infinite;
           animation: ball-scale-multiple 1s 0s linear infinite;
}
.la-ball-scale-multiple > div:nth-child(2) {
   -webkit-animation-delay: .2s;
      -moz-animation-delay: .2s;
        -o-animation-delay: .2s;
           animation-delay: .2s;
}
.la-ball-scale-multiple > div:nth-child(3) {
   -webkit-animation-delay: .4s;
      -moz-animation-delay: .4s;
        -o-animation-delay: .4s;
           animation-delay: .4s;
}
.la-ball-scale-multiple.la-sm {
   width: 16px;
   height: 16px;
}
.la-ball-scale-multiple.la-sm > div {
   width: 16px;
   height: 16px;
}
.la-ball-scale-multiple.la-2x {
   width: 64px;
   height: 64px;
}
.la-ball-scale-multiple.la-2x > div {
   width: 64px;
   height: 64px;
}
.la-ball-scale-multiple.la-3x {
   width: 96px;
   height: 96px;
}
.la-ball-scale-multiple.la-3x > div {
   width: 96px;
   height: 96px;
}
/*
* Animation
*/
@-webkit-keyframes ball-scale-multiple {
   0% {
       opacity: 0;
       -webkit-transform: scale(0);
               transform: scale(0);
   }
   5% {
       opacity: .75;
   }
   100% {
       opacity: 0;
       -webkit-transform: scale(1);
               transform: scale(1);
   }
}
@-moz-keyframes ball-scale-multiple {
   0% {
       opacity: 0;
       -moz-transform: scale(0);
            transform: scale(0);
   }
   5% {
       opacity: .75;
   }
   100% {
       opacity: 0;
       -moz-transform: scale(1);
            transform: scale(1);
   }
}
@-o-keyframes ball-scale-multiple {
   0% {
       opacity: 0;
       -o-transform: scale(0);
          transform: scale(0);
   }
   5% {
       opacity: .75;
   }
   100% {
       opacity: 0;
       -o-transform: scale(1);
          transform: scale(1);
   }
}
@keyframes ball-scale-multiple {
   0% {
       opacity: 0;
       -webkit-transform: scale(0);
          -moz-transform: scale(0);
            -o-transform: scale(0);
               transform: scale(0);
   }
   5% {
       opacity: .75;
   }
   100% {
       opacity: 0;
       -webkit-transform: scale(1);
          -moz-transform: scale(1);
            -o-transform: scale(1);
               transform: scale(1);
   }
}



.card-animated {
  transition : all 0.1s ease-in-out;
  opacity : 0.8;
  transform : translateY(-8px);
}
.card-animated.card-animated-mounted {    
  opacity : 1;
  transform : translateY(0px);
}

.project-animated {    
  opacity : 0.6;
  transform : translateY(-8px);
  transition : 0.24s ease-in-out;
  transition-property: opacity, transform;
}
.project-animated-mounted {    
  opacity : 1;
  transform : translateY(0px);
}

.dashboard-card-animated {
  transition : all 0.2s ease-in-out;
  opacity : 0.3;
  transform : translateY(-8px);    
}
.dashboard-card-animated.dashboard-card-animated-mounted {    
  opacity : 1;
  transform : translateY(0px);
}
.dashboard-card-animated.dashboard-card-animated-mounted:hover {    
  transform : scale(1.02);
}

.card-animated-fade {
  opacity : 0.6;
  transition : all 0.2s ease;
}
.card-animated-fade.card-animated-mounted {    
  opacity : 1;
}

.card-animated-fade-fromleft {
  transition : all 0.2s ease-out;
  opacity : 0.4;
  transform : translateX(-12px);
}
.card-animated-fade-fromleft.card-animated-mounted {    
  opacity : 1;
  transform : translateX(0);
}
.card-animated-fade-fromleft-closing {
  transition : all 0.1s ease-in;
  opacity : 0;
  transform : translateX(-12px);
}

.card-animated-fade-fromright {
  transition : all 0.1s ease;
  opacity : 0.4;
  transform : translateX(12px);
  width: 100%;
}
.card-animated-fade-fromright.card-animated-mounted {    
  opacity : 1;
  transform : translateX(0);
}
.card-animated-fade-fromright-closing {
  transition : all 0.1s ease;
  opacity : 0;
  transform : translateX(12px);
}

iframe {
  border : none;
}

.grid {
  position: relative;
}
.item {
  display: block;
  position: absolute;
  z-index: 1;
  width : auto;
  height : auto;
}
.item.muuri-item-dragging {
  z-index: 3;
}
.item.muuri-item-releasing {
  z-index: 2;
}
.item.muuri-item-hidden {
  z-index: 0;
}
.item-content {
  position: relative;
  width: 100%;
  height: 100%;
}



.slider {
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
}
.slider:focus {
  outline: none;
}
.slider::-webkit-slider-runnable-track {    
  padding-top : 2px;
	padding-bottom : 2px;
cursor: pointer;
position: relative;
background-color: transparent;
width: 100%;
display: flex;
align-items: center;

}
.slider::-webkit-slider-thumb {
  cursor: pointer;
width: 12px;
height: 12px;
background-color: transparent;
border-style: solid;
border-radius: 99px;
border-width: 1px;
border-color: rgb(224, 22, 22);
z-index: 10;
opacity : 0;

  -webkit-appearance: none;
}
.slider:focus::-webkit-slider-runnable-track {
  
}
.slider:active::-webkit-slider-thumb {
  transform : scale(1.2);
}
.slider::-moz-range-track {  
  padding-top : 2px;
	padding-bottom : 2px;
cursor: pointer;
position: relative;
background-color: rgb(99, 99, 103);
width: 100%;
display: flex;
align-items: center;

}
.slider::-moz-range-thumb {
  cursor: pointer;
width: 18px;
height: 18px;
background-color: rgba(245, 245, 245, 1);
border-style: solid;
border-radius: 99px;
border-width: 2px;
border-color: rgb(99, 99, 103);
z-index: 10;

}
.slider::-ms-track {
  padding-top : 2px;
	padding-bottom : 2px;
cursor: pointer;
position: relative;
background-color: rgb(99, 99, 103);
width: 100%;
display: flex;
align-items: center;

}
.slider::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
.slider::-ms-fill-upper {
  background: undefined;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
.slider::-ms-thumb {
  cursor: pointer;
width: 18px;
height: 18px;
background-color: rgba(245, 245, 245, 1);
border-style: solid;
border-radius: 99px;
border-width: 2px;
border-color: rgb(99, 99, 103);
z-index: 10;

}
.slider:focus::-ms-fill-lower {
  background: undefined;
}
.slider:focus::-ms-fill-upper {
  background: #367ebd;
}

input[type=range]{
  /* fix for FF unable to apply focus style bug  */
  border: none;

  /*required for proper track sizing in FF*/
}

input[type=range]::-moz-range-track {
  background: transparent;
  border: none;
  border-radius: 3px;
}

input[type=range]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: transparent;
}

/*hide the outline behind the border*/
input[type=range]:-moz-focusring{
  outline: none;
  outline-offset: -1px;
}

input[type=range]:focus::-moz-range-track {
  background: transparent;
}

/* Device Slider */

.device-slider {
  -webkit-appearance: none;
  margin: 9px 0;
  width: 100%;
}
.device-slider:focus {
  outline: none;
}
.device-slider::-webkit-slider-runnable-track {
  padding-top : 2px;
	padding-bottom : 2px;
cursor: pointer;
position: relative;
background-color: transparent;
width: 100%;
display: flex;
align-items: center;

}
.device-slider::-webkit-slider-thumb {
  cursor: pointer;
width: 24px;
height: 24px;
background-color: transparent;
z-index: 10;
opacity: 0;
background-color: transparent;
border-style: solid;
border-radius: 99px;
border-width: 1px;
border-color: rgb(99, 99, 103);
  -webkit-appearance: none;
}
.device-slider:focus::-webkit-slider-runnable-track {
  
}
.device-slider:active::-webkit-slider-thumb {
  transform : scale(1.2);
}
.device-slider::-moz-range-track {
  padding-top : 2px;
	padding-bottom : 2px;
cursor: pointer;
position: relative;
background-color: rgb(99, 99, 103);
width: 100%;
display: flex;
align-items: center;

}
.device-slider::-moz-range-thumb {
  cursor: pointer;
width: 24px;
height: 24px;
z-index: 10;
opacity: 0;
}
.device-slider::-ms-track {
  padding-top : 2px;
	padding-bottom : 2px;
cursor: pointer;
position: relative;
background-color: rgb(99, 99, 103);
width: 100%;
display: flex;
align-items: center;

}
.device-slider::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
.device-slider::-ms-fill-upper {
  background: undefined;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
.device-slider::-ms-thumb {
  width: 24px;
height: 24px;
background-color: transparent;
z-index: 10;
opacity : 0;

}
.device-slider:focus::-ms-fill-lower {
  background: undefined;
}
.device-slider:focus::-ms-fill-upper {
  background: #367ebd;
}
